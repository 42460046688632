// questions contants
export const CHANGE_QUESTION = 'LOADED';
export const CHANGE_QUESTION_STATUS = 'CHANGE_QUESTION_STATUS';
export const CREATE_QUESTION_TAB = 'CREATE_QUESTION_TAB';
export const CHANGE_QUESTION_TAB = 'CHANGE_QUESTION_TAB';
export const DELETE_QUESTION_TAB = 'DELETE_QUESTION_TAB';
export const CHANGE_QUESTION_TAB_RESPONSE = 'CHANGE_QUESTION_TAB_RESPONSE';
export const CHANGE_QUESTION_TAB_ERROR = 'CHANGE_QUESTION_TAB_ERROR';
export const CHANGE_QUESTION_TAB_TEXT = 'CHANGE_QUESTION_TAB_TEXT';
export const CHANGE_QUESTION_TAB_IS_CHECKING = 'CHANGE_QUESTION_TAB_IS_CHECKING';
export const CHANGE_QUESTION_TAB_IS_SUBMITTING = 'CHANGE_QUESTION_TAB_IS_SUBMITTING';
export const CHANGE_QUESTION_TAB_STASUS = 'CHANGE_QUESTION_TAB_STASUS';
export const CHANGE_QUESTION_REVEALED_STATUS = 'CHANGE_QUESTION_REVEALED_STATUS';
export const REPLACE_TABS = 'REPLACE_TABS';

// trainer
export const TRAINER_LOADED = 'TRAINER_LOADED';
export const TRAINER_TOGGLE_ERD_MODAL = 'TRAINER_TOGGLE_ERD_MODAL';
