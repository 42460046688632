import { combineReducers } from 'redux';

import main from './mainReducer';
import questions from './questionReducer';

const appReducer = combineReducers({
    main,
    questions,
});

export { questions, main };

export default appReducer;
