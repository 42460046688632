import env from '@beam-australia/react-env';

import { isServerSide } from './next-js-utils';

const isDevelopment = process.env.NODE_ENV === 'development';
const isProduction = process.env.NODE_ENV === 'production';

const apiUrl = isServerSide ? env('SERVER_SIDE_API_URL') : env('API_URL');

const publicUrl = env('PUBLIC_URL');

const openExerciseCertificateExampleId = env('OPEN_EXERCISE_CERTIFICATE_EXAMPLE_ID');

export {
    isDevelopment,
    isProduction,
    apiUrl,
    publicUrl,
    openExerciseCertificateExampleId,
};
