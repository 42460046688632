import { useMemo } from 'react';

import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers } from 'redux-immer';
import produce from 'immer';

import { isServerSide } from '@lib/next-js-utils';

import trainerReducer from './reducers/trainer';
import userReducer from './reducers/user';

const CLIENT_REHYDRATE = 'CLIENT_REHYDRATE';

let storeInstance;

const reducer = combineReducers(produce, { trainer: trainerReducer, user: userReducer });

const rootReducer = (state = {}, action) => {
    if (action.type === CLIENT_REHYDRATE) {
        return action.payload;
    }

    return reducer(state, action);
};

export function initializeStore(initialState) {
    if (!storeInstance || isServerSide) {
        storeInstance = createStore(
            rootReducer,
            initialState,
            composeWithDevTools(applyMiddleware(thunk)),
        );

        return storeInstance;
    }

    if (initialState) {
        storeInstance.dispatch({
            type: CLIENT_REHYDRATE,
            payload: initialState,
        });
    }

    return storeInstance;
}

export const useStore = (initialState) => {
    return useMemo(() => initializeStore(initialState), [initialState]);
};
