import * as types from '../../constants/user';

const initialState = {};

const profile = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_CHANGE_PROFILE: {
            return {
                ...action.payload,
            };
        }

        default:
            return state;
    }
};

export default profile;
