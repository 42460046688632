import * as types from '../../constants/trainer';

const initialState = {
    question: undefined,
};

const questions = (state = initialState, action) => {
    const { question } = state;

    switch (action.type) {
        case types.CHANGE_QUESTION:
            state.question = action.payload;

            return state;

        case types.CHANGE_QUESTION_STATUS: {
            const { status } = action;

            question.status = question.status !== 'success' ? status : 'success';

            if (status === 'accepted') {
                question.isSolved = true;
            }

            return state;
        }

        case types.CREATE_QUESTION_TAB: {
            question.tabs.push({
                ...action.payload,
                id: question.tabs.length,
            });

            question.tabIndex = question.tabs.length - 1;

            return state;
        }

        case types.CHANGE_QUESTION_TAB: {
            question.tabIndex = action.payload;

            return state;
        }

        case types.DELETE_QUESTION_TAB: {
            if (question.tabs.length !== 1) {
                question.tabs.splice(question.tabIndex, 1);

                question.tabIndex = question.tabIndex === 0 ? 0 : question.tabIndex - 1;
            }

            return state;
        }

        case types.CHANGE_QUESTION_TAB_RESPONSE: {
            const { tabIndex, response } = action;

            question.tabs[tabIndex].response = response;

            return state;
        }

        case types.CHANGE_QUESTION_TAB_ERROR: {
            const { tabIndex, error } = action;

            question.tabs[tabIndex].error = error;

            return state;
        }

        case types.CHANGE_QUESTION_TAB_TEXT: {
            const { tabIndex, text } = action;

            question.tabs[tabIndex].text = text;

            return state;
        }

        case types.CHANGE_QUESTION_TAB_IS_CHECKING: {
            const { tabIndex, isChecking } = action;

            question.tabs[tabIndex].isChecking = isChecking;

            return state;
        }

        case types.CHANGE_QUESTION_TAB_IS_SUBMITTING: {
            const { tabIndex, isSubmitting } = action.payload;

            question.tabs[tabIndex].isSubmitting = isSubmitting;

            return state;
        }

        case types.CHANGE_QUESTION_TAB_STASUS: {
            const { tabIndex, status } = action;

            question.tabs[tabIndex].status = status;

            return state;
        }

        case types.CHANGE_QUESTION_REVEALED_STATUS: {
            const { isRevealed } = action;

            question.isRevealed = isRevealed;

            return state;
        }

        case types.REPLACE_TABS: {
            const { tabs, tabIndex } = action;

            question.tabs = tabs;
            question.tabIndex = tabIndex;

            return state;
        }

        default:
            return state;
    }
};

export default questions;
