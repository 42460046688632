import { isNil, omitBy } from 'lodash';
import { stringifyUrl } from 'query-string';

import { objectToSearchString } from 'use-query-params';

import { publicUrl } from '@lib/env';

import { isServerSide } from './next-js-utils';

export const createShareLink = ({ origin = '', sql, database }) => {
    return stringifyUrl({
        url: origin,
        query: {
            share: Buffer.from(
                objectToSearchString(omitBy({ sql, database }, isNil)),
            ).toString('base64'),
        },
    });
};

export const parseUrl = (url) => {
    return new URL(url, publicUrl);
};

export const DATABASE = {
    Trip: '4ed2b809d7446b9a0e100001',
    Family: '4ed2b809d7446b9a0e100002',
    Schedule: '4ed2b809d7446b9a0e100003',
    Airbnb: '4ed2b809d7446b9a0e100004',
};

export const createLinkWithHashForCurrentPage = (hash) => {
    return isServerSide ? '' : `${location.origin}${location.pathname}#${hash}`;
};

export const capitalizeFirstLetter = (value) => {
    return value.slice(0, 1).toUpperCase() + value.slice(1);
};

export const sleep = (duration) => {
    return new Promise((res) => setTimeout(res, duration));
};

export const ellipsizeText = (text = '', maxLength, tail = '...') => {
    return text?.length > maxLength ? `${text.slice(0, maxLength)}${tail}` : text;
};

export const formatCurrency = (
    value: number,
    currency: string,
    options?: { showFractionDigits?: boolean; currencyFirst?: boolean },
) => {
    const { showFractionDigits = true, currencyFirst = false } = options || {};

    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'decimal',
        minimumFractionDigits: showFractionDigits ? 2 : 0,
    });

    return currencyFirst
        ? [formatter.format(value / 100), currency].reverse().join(' ')
        : [formatter.format(value / 100), currency].join(' ');
};

export const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
