import NextLink from 'next/link';
import NextRouter from 'next/router';

import NextUseTranslation from 'next-translate/useTranslation';
import NextWithTranslation from 'next-translate/withTranslation';

import { isServerSide } from './next-js-utils';

export const Router = NextRouter;
export const Link = NextLink;

export const useTranslation: typeof NextUseTranslation = (namespace) => {
    const data = NextUseTranslation(namespace);

    return {
        ...data,
        lang: getLanguageFromNextLocale(data.lang),
    };
};

export const withTranslation = (namespace: string) => (Component) => {
    const C = ({ i18n, ...other }) => {
        return <Component {...other} t={i18n.t} lang={i18n.lang} />;
    };

    return NextWithTranslation(C, namespace);
};

export const useRouter = () => {
    return NextRouter;
};

const getCurrentLocale = () => {
    if (isServerSide) return undefined;

    return getLanguageFromNextLocale(Router.locale);
};

const getValueForCurrentLanguage = <
    T extends { [key in string]?: any },
    R extends T[keyof T],
>(
    values: T,
): R | undefined => {
    const language = getCurrentLocale();

    if (!language) return undefined;

    return values[language];
};

const getLanguageFromNextLocale = (locale: string | undefined | null): string => {
    const map = {
        default: 'ru',
        ru: 'ru',
        en: 'en',
    };

    if (!locale) {
        return map.default;
    }

    return map[locale] || map.default;
};

export { getValueForCurrentLanguage, getLanguageFromNextLocale, getCurrentLocale };
