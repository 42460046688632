import * as types from '../../constants/trainer';

const initialState = {
    isLoading: true,
    timer: 0,
    resultModalIsOpened: false,
    ERDModalIsOpened: false,
};

const trainer = (state = initialState, action) => {
    switch (action.type) {
        case types.TRAINER_LOADED:
            return {
                ...state,
                isLoading: action.payload,
            };

        case types.TRAINER_TOGGLE_ERD_MODAL:
            return {
                ...state,
                ERDModalIsOpened: !state.ERDModalIsOpened,
            };

        default:
            return state;
    }
};

export default trainer;
