import {
    QueryClient,
    QueryKey,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query';

import { capitalizeFirstLetter } from './utils';

const queryClient = new QueryClient();

const invalidateCache = (cacheKey: QueryKey) => {
    return queryClient.invalidateQueries(cacheKey);
};

const resetQuery = (cacheKey: QueryKey) => {
    return queryClient.resetQueries(cacheKey);
};

type CreateQueryReturn<H extends string, P, TData> = {
    [key in H]: P extends undefined
        ? (params?: P, options?: UseQueryOptions<TData>) => UseQueryResult<TData>
        : (params: P, options?: UseQueryOptions<TData>) => UseQueryResult<TData>;
} & {
    resetQueryCache: () => Promise<void>;
    invalidateQueryCache: () => Promise<void>;
};

const createQuery = <
    T extends string,
    H extends `use${Capitalize<T>}`,
    R extends (params: any) => Parameters<typeof useQuery>,
    P extends Parameters<R>[0],
    QueryData extends Awaited<ReturnType<ReturnType<R>[1]>>,
    TData extends IfAny<QueryData, unknown, QueryData>,
>(
    cacheKey: T,
    queryCreator: R,
) => {
    const resetQueryCache = () => resetQuery(cacheKey);
    const invalidateQueryCache = () => invalidateCache(cacheKey);

    return {
        invalidateQueryCache,
        resetQueryCache,

        [`use${capitalizeFirstLetter(cacheKey)}`]: (
            params: P,
            options?: UseQueryOptions,
        ) => {
            const [queryKey, queryFn, queryOptions] = queryCreator(params);

            return useQuery(queryKey, queryFn, { ...queryOptions, ...options });
        },
    } as CreateQueryReturn<H, P, TData>;
};

export * from 'react-query';
export { queryClient, invalidateCache, resetQuery, createQuery };
