import * as types from '../../constants/user';

const initialState = {
    isAuthorized: false,
    authModalIsOpened: false,
    authModalIsLogin: true,
    authModalDataIsLoading: false,
    authModalRequestResult: null,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_CHANGE_IS_AUTHORIZED: {
            return {
                ...state,
                isAuthorized: action.payload,
            };
        }

        case types.USER_TOGGLE_AUTH_MODAL: {
            return {
                ...state,
                authModalIsOpened: !state.authModalIsOpened,
            };
        }

        case types.USER_TOGGLE_AUTH_MODAL_IS_LOGIN: {
            return {
                ...state,
                authModalIsLogin: !state.authModalIsLogin,
                authModalRequestResult: null,
            };
        }

        case types.USER_TOGGLE_AUTH_MODAL_IS_LOADING: {
            return {
                ...state,
                authModalDataIsLoading: action.payload,
            };
        }

        case types.USER_CHANGE_AUTH_MODAL_DATA_RESULT: {
            return {
                ...state,
                authModalRequestResult: action.payload,
            };
        }

        default:
            return state;
    }
};

export default auth;
