import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { getCurrentLocale } from '@lib/i18n';
import { isClientSide } from '@lib/next-js-utils';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';

const datetime = dayjs;

datetime.locale('ru');

dayjs.extend(localizedFormat);

const updateDatetimeLocale = (language) => {
    datetime.locale(language);
};

const useSyncDayjsLocaleWithCookie = () => {
    if (isClientSide) {
        const locale = getCurrentLocale();

        updateDatetimeLocale(locale);
    }
};

export { useSyncDayjsLocaleWithCookie, updateDatetimeLocale };

export default datetime;
