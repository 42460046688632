const firebaseConfig = {
    apiKey: 'AIzaSyCiTiPvMdE2MBbL3m5sRAdbwO7DSvVeevM',
    authDomain: 'sql-academy-5c867.firebaseapp.com',
    projectId: 'sql-academy-5c867',
    storageBucket: 'sql-academy-5c867.appspot.com',
    messagingSenderId: '159635615543',
    appId: '1:159635615543:web:a7fabd2ef7fd0599db34b2',
    measurementId: 'G-ZJR9R0RQB2',
};

export { firebaseConfig };
