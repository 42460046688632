import React, { Component } from 'react';
import { NotificationContainer } from 'react-notifications';

import './index.scss';

class Notification extends Component {
    render() {
        return <NotificationContainer />;
    }
}

export default Notification;
