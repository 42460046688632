import { combineReducers } from 'redux';
import auth from './authReducer';
import profile from './profileReducer';

const appReducer = combineReducers({
    auth,
    profile,
});

export { auth };

export default appReducer;
